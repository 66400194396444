import React, { useState } from 'react';
import './emailForm.css';

function EmailForm() {
    const [formData, setFormData] = useState({
        name: '',
        headOfHousehold: 'Yes',
        namedInternetBill: 'Yes',
        tribalMemberID: '',
        address: '',
        ispName: '',
        internetAccountNumber: '',
        fileNames: [],
        timestamp: '',
    });
    // file changes and storage
    const [files, setFiles] = useState([]);

    // this is what client sees when response comes back from API
    const [resultText, setResultText] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    // text changes for fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        // if the list has 2 files in it already, throw error, ask to reset form
        if (files.length > 2) {
            setResultText("An error occurred. The Application was Reset. We apologize for the inconvenience")
            resetForm()
        } else {
            // add file.name into formData files list, for sendARPAEmail api to process from
            setFormData({ ...formData, fileNames: [...formData.fileNames, file.name] })
            setFiles([...files, file])
        }
    }

    const handleSignature = () => {
        const now = new Date()
        const locale = now.toLocaleString('en-US')
        setFormData({ ...formData, timestamp: locale });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // if file are not null we go to upload files
        if (files.length > 0 || files.length < 1) {
            // separate fetch calls to API for each file to upload separately
            for (let i = 0; i < files.length; i++) {
                const fileName = formData.fileNames[i]
                console.log("Processing file:", fileName)
                const uploadAPI = `https://2yzfb6oh6j.execute-api.us-east-1.amazonaws.com/stage/tununakbucket/${fileName}`
                try {
                    await fetch(uploadAPI, {
                        method: "PUT",
                        body: files[i],
                    });
                } catch (error) {
                    console.error('Error uploading files:', error);
                }
            }
        }

        try {
            const apiUrl = "https://5rn5w4mgm4.execute-api.us-east-1.amazonaws.com/default/sendARPAEmail"
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'x-api-key': process.env.REACT_APP_SEND_EMAIL
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`Error in fetch! status: ${response.status}`);
            }

            setResultText("Email sent successfully! Thank you for your application!");
            resetForm();
            return response.json();

        } catch (error) {
            setResultText("An unkown error occured. Email was not sent!");
        }
        resetForm();
    };

    // Reset form
    const resetForm = () => {
        setIsLoading(false);
        setFiles([]);
        setFormData({
            name: '',
            headOfHousehold: 'Yes',
            namedInternetBill: 'Yes',
            tribalMemberID: '',
            address: '',
            ispName: '',
            internetAccountNumber: '',
            fileNames: [],
            timestamp: ''
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h6>
                By filling out this form you are signing this Application electronically.
                Please note that an eSignature is the electronic equivalent of a handwritten signature.
                You agree your electronic signature is the legal equivalent of your handwritten signature on this Application.
                Your electronic signature certifies that all information provided on the application is true and correct.
                Do Not E-Sign Until You Have Read The Above Statement.
                Please signify your acceptance by entering the information below:
            </h6>
            <div>
                <div>
                    <label>Full Name: </label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </div>

                <div>
                    <label>Are you head of household? </label>
                    <label>
                        <input
                            type="radio"
                            name="headOfHousehold"
                            value="Yes"
                            checked={formData.headOfHousehold === 'Yes'}
                            onChange={handleChange}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="headOfHousehold"
                            value="No"
                            checked={formData.headOfHousehold === 'No'}
                            onChange={handleChange}
                        />
                        No
                    </label>
                </div>

                <div>
                    <label>Is the internet bill in your name? </label>
                    <label>
                        <input
                            type="radio"
                            name="namedInternetBill"
                            value="Yes" checked={formData.namedInternetBill === 'Yes'}
                            onChange={handleChange}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="namedInternetBill"
                            value="No"
                            checked={formData.namedInternetBill === 'No'}
                            onChange={handleChange} />
                        No
                    </label>
                </div>

                <div>
                    <label>Tribal Member ID: </label>
                    <input
                        type="text"
                        name="tribalMemberID"
                        value={formData.tribalMemberID}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label>Address: </label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Name of Internet Service Provider: </label>
                    <input
                        type="text"
                        name="ispName"
                        value={formData.ispName}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label>Internet Account Number: </label>
                    <input
                        type="text"
                        name="internetAccountNumber"
                        value={formData.internetAccountNumber}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label>Attach Tribal ID: </label>
                    <input type="file" name="id" onChange={handleFileChange} disabled={files.length > 1} />
                </div>

                <div>
                    <label>Attach Internet Bill: </label>
                    <input type="file" name="bill" onChange={handleFileChange} disabled={files.length > 1} />
                </div>

                {/* Display added files */}
                {files.map((file, index) => (
                    <div className='file-span' key={index}>File added: {file.name}</div>
                ))}

                <div>
                    <h4 className="signature-span">
                        I certify that I have read, fully understand and
                        accept all terms of the foregoing statement.
                    </h4>
                </div>
                <div>
                    <span className="signature-span">
                        <label>
                            By checking this box, I am electronically signing this Application.
                        </label>
                        <input type="checkbox" name="timestamp" onClick={handleSignature} required />
                    </span>
                    <br />
                </div>

                <div>
                    <span className="button-span">
                        <button className='reset-button' type='reset' onClick={resetForm}>Reset</button>
                        <button className='submit-button' type="submit" disabled={isLoading}> Submit Application</button>
                    </span>
                </div>

                <div>
                    {isLoading && <h4>Submitting Your Application ... Please Wait</h4>}
                    <h4 id="result-text">{resultText}</h4>
                </div>
            </div>
        </form >
    );
}

export default EmailForm;
