import { Routes, Route, Link } from "react-router-dom";
import logo from "./assets/logo.jpg";
import map from "./assets/map.png";
import pdf from "./assets/Economic Assistance.pdf";
import infoIcon from "./assets/tununak-info-icon.jpeg";
import infoFull from "./assets/tununak-info-full.jpg";
import mapIcon from "./assets/tununak-community-map-icon.jpeg";
import mapFull from "./assets/tununak-community-map-full.jpg";
import EmailForm from "./EmailForm.jsx";
import "./App.css";

function Navigation() {
    return (
        <nav>
            <Link to="/" className="nav-button">
                Home
            </Link>
            <Link to="/board" className="nav-button">
                Board
            </Link>
            <Link to="/history" className="nav-button">
                History
            </Link>
        </nav>
    );
}
function Home() {
    return (
        <>
            <Navigation />
            <main>
                <div className="home-img-container">
                    <img src={map} alt="location of Tununak in Alaska" />
                </div>
                <div className="App-link-container">
                    <h3>ARPA Starlink/Internet Application for $500 credit<br />
                        Application Deadline is August 31, 2024<br />
                    </h3>
                    <h6 className='form-details'>This is application is for Enrolled tribal Members not living in Tununak.<br />
                        You must be:<br />
                        1. Head of Household<br />
                        2. Assist up to $500 Direct Vendor Payment to internet provider<br />
                    </h6>
                    <EmailForm />
                    <br />
                    <a
                        className="App-link"
                        href={pdf}
                        download={pdf}
                        title="Download pdf of Economic Assistance Application"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Download PDF of Economic Assistance Application
                    </a>
                    <br />
                    <a
                        className="App-link"
                        href={pdf}
                        title="View the Economic Assistance Application"
                        target="_blank"
                        rel="noreferrer"
                    >
                        View the Economic Assistance Application
                    </a>
                </div>
            </main>
        </>
    );
}

function Board() {
    return (
        <>
            <Navigation />
            <main>
                <h3>Our Board</h3>
                <p>This page is under construction</p>
                <p>Check back again later</p>
            </main>
        </>
    );
}

function History() {
    return (
        <>
            <Navigation />
            <main>
                <h3>Our History</h3>
                <div className="history-container">
                    <div className="info-image-container">
                        <p>Information</p>
                        <a
                            href={infoFull}
                            className="history-images"
                            title="Full size image of community information"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={infoIcon} alt="community information icon" />
                        </a>
                    </div>
                    <div className="map-image-container">
                        <p>Community Map</p>
                        <a
                            href={mapFull}
                            className="history-images"
                            title="Full size image of community map"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={mapIcon} alt="community map icon" />
                        </a>
                    </div>
                </div>
            </main>
        </>
    );
}

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>Tununak</h1>
                <h2>Native Village</h2>
            </header>
            <caption>Eric Ellefson, 2010</caption>

            <navbar className="navbar">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="board" element={<Board />} />
                    <Route path="history" element={<History />} />
                    <Route path="emailform" element={<EmailForm />} />
                </Routes>
            </navbar>

            <main></main>

            <footer>copyright &copy; 2022 | Created by <a href="https://www.linkedin.com/in/vlad-solokha/">Vlad Solokha</a></footer>
        </div>
    );
}

export default App;
